import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PermissionsCategory, PermissionsObject, User } from '../../model/User';

import { MapperService } from './mapper.service';
import { MediaService } from './media.service';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends MapperService {
  public invitedUsers$? = new BehaviorSubject<string[] | undefined>([]);
  private _user = new BehaviorSubject<User | null>(null);
  private _avatar$ = new BehaviorSubject<string | null>(null);
  private loggedOut$ = new Subject<void>();

  constructor(http: HttpClient, private workspaceService: WorkspaceService, private mediaService: MediaService) {
    super('users', http);
  }

  public get user$() {
    return this._user.asObservable().pipe(
      takeUntil(this.loggedOut$),
      switchMap((user: User | null) => {
        if (user) {
          return of(user);
        }
        return this.getProfile().pipe(
          tap((usr) => {
            this._user.next(usr);
          }),
          catchError(() => {
            return of(null);
          })
        );
      })
    );
  }

  public get avatar$() {
    if (!this._avatar$.value?.length) {
      this.user$
        .pipe(
          mergeMap((user) => {
            return this.getAvatar(user?._id);
          })
        )
        .subscribe((avatar) => this._avatar$.next(avatar));
    }
    return this._avatar$.asObservable();
  }

  public categoryFeaturePermission$(
    category: PermissionsCategory,
    selector: string | string[]
  ): Observable<boolean | null> {
    return this.categoryPermissions$(category).pipe(
      map((permissions) => {
        if (typeof selector === 'string') {
          return permissions?.[selector] ?? null;
        } else {
          return selector.some((s) => permissions?.[s] ?? null);
        }
      })
    );
  }

  public categoryPermissions$(category: PermissionsCategory): Observable<PermissionsObject | null> {
    return this.user$.pipe(
      mergeMap((user: User) => {
        if (!user) {
          return of(null);
        }
        return of(user.permissions.frontend[category] ?? null);
      })
    );
  }

  public setAvatar(id: string) {
    this._avatar$.next(id);
  }

  public invalidateUser() {
    this._user.next(null);
  }

  public getProfile() {
    return this.get('profile')();
  }

  public updateProfile(body: any) {
    return this.create('profile')(body);
  }

  public registerAccount(body: Partial<User>, params?: any) {
    return this.create('')(body, params);
  }

  public activateAccount(token: string, params?: any) {
    return this.create('activation')({ token }, params);
  }

  public recoverPassword(email: string) {
    return this.create(
      'recovery',
      {
        responseType: 'text'
      },
      'text/plain'
    )({ email });
  }

  public resetPassword(password: string, token: string) {
    return this.create(
      'recovery',
      {
        responseType: 'text'
      },
      'text/plain'
    )({ password, token });
  }

  public login(email: string, password: string) {
    return this.create('login')({ email, password });
  }

  public logout() {
    return this.create('logout')().pipe(
      tap(() => {
        this.loggedOut$.next();
        this._user.next(null);
        this._avatar$.next(null);
        this.workspaceService.setCurrentWorkspace(null);
      })
    );
  }

  public invite(email: string, workspaceId: string) {
    return this.create('invite')({ email, workspace: workspaceId });
  }

  public getInvitedUsers() {
    return this.invitedUsers$?.asObservable();
  }

  public verify(token) {
    return this.create('verify')({ response: token });
  }

  public getAvatar(userId?: any): Observable<string> {
    return this.mediaService.listMedia({ type: 'profile', ref: userId }).pipe(map((media) => media.data[0]?._id));
  }
}
