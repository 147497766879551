import { HttpClient, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Media } from '../../model/Media';

import { MapperService } from './mapper.service';

/**
 * Replace special URL characters for underscore
 * @param filename
 */
const normalizeFileName = (filename: string) => {
  const parts = filename.split('.');
  const ext = parts.splice(-1);
  const filenameRest = parts.join('.').trim();
  return filenameRest.replace(/;|,|\/|\?|:|@|&|=|\+|\$|-|_|\.|!|~|\*|'|\(|\)|#|\s/gi, '_').concat('.' + ext);
};

const objectToFormData: (obj: object) => FormData = (obj: object) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, val]) => formData.append(key, val));
  return formData;
};

export interface UploadProgressResponse {
  progress?: { total?: number; loaded?: number } | null;
  response?: HttpResponse<any> | null;
}

@Injectable({
  providedIn: 'root'
})
export class MediaService extends MapperService<Media> {
  constructor(http: HttpClient) {
    super('media', http);
  }

  public updateMedia(id: string, body: any) {
    return this.update('')(id, body);
  }

  public listMedia(params?: any) {
    return this.list('')(params);
  }

  public getMedia(id: string) {
    return this.get('')(id);
  }

  public generateMarker(mediaId: string) {
    return this.create<any>(`${mediaId}/generate`)({ params: '-min_dpi=72 -max_dpi=300' });
  }

  /**
   * Update media file with new file that will be uploaded
   *
   * @param id
   * @param file
   * @param type
   * @returns
   */
  public updateWithUpload(id: string, file: File, type: string): Observable<UploadProgressResponse> {
    const options = {
      name: normalizeFileName(file.name),
      type,
      file
    };
    const formData = objectToFormData(options);
    const url = this.getUrl(id);
    return this.requestWithProgress('PATCH', url, formData).pipe(
      // Workaround to prevent crash of backend media module
      tap((res) => {
        if (res.response) {
          const media = res.response.body as Media;
          this.getMedia(media._id).subscribe();
        }
      })
    );
  }

  public uploadFileWithProgress(file: File, type: string, ref: string): Observable<UploadProgressResponse> {
    const options = {
      ref,
      name: normalizeFileName(file.name),
      type,
      file
    };
    const formData = objectToFormData(options);
    const url = this.getUrl();

    return this.requestWithProgress('POST', url, formData);
  }

  private requestWithProgress(method: string, url: string, formData: FormData): Observable<UploadProgressResponse> {
    return new Observable((obs) => {
      this.http
        .request(method, url, {
          body: formData,
          withCredentials: true,
          reportProgress: true,
          observe: 'events',
          // Note: ngsw-bypass used to bypass service worker request handling
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { authorization: `Basic ${this.authToken}`, 'ngsw-bypass': '1' }
        })
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progressEvent = event as HttpProgressEvent;
              obs.next({ progress: { total: progressEvent.total, loaded: progressEvent.loaded }, response: null });
            }
            if (event.type === HttpEventType.Response) {
              const responseEvent = event as HttpResponse<Media>;
              obs.next({ progress: null, response: responseEvent });
              obs.complete();
            }
          },
          (err) => {
            obs.error(err);
            obs.complete();
          }
        );
    });
  }
}
