import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { FileData } from '@app/shared/components/upload/upload.component';

import { Workspace } from '../../model/Workspace';

import { MapperService } from './mapper.service';
import { MediaService } from './media.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService extends MapperService {
  private _workspaces$ = new BehaviorSubject<Workspace[] | null>(null);
  private _currentWorkspace$ = new BehaviorSubject<Workspace | null>(null);

  constructor(http: HttpClient, private mediaService: MediaService) {
    super('workspaces', http);
  }

  public get currentWorkspace$() {
    return this._currentWorkspace$.asObservable();
  }

  public get currentWorkspaceValue() {
    return this._currentWorkspace$.value;
  }

  public get workspaces$() {
    if (!this._workspaces$.value?.length) {
      this.getWorkspaces().subscribe((workspaces) => this._workspaces$.next(workspaces));
    }
    return this._workspaces$.asObservable();
  }

  public setCurrentWorkspace(ws: Workspace) {
    this._currentWorkspace$.next(ws);
  }

  public getWorkspaces(): Observable<Workspace[]> {
    return this.list('')({ status: 'active' }).pipe(map(({ data }) => data));
  }

  public getWorkspace(workspace: string): Observable<Workspace> {
    return this.listAll('?status=active&slug=' + workspace).pipe(map(([ws]) => ws)) as Observable<Workspace>;
  }

  public getWorkspaceUsers(workspace: string, params?: any) {
    return this.list(`${workspace}/users`)(params).pipe(
      map((user) => {
        const userIds = this.buildUserIdsParam(user.data);
        return this.listUserListMedia(userIds, user);
      })
    );
  }

  public switchWorkspace(id: string) {
    return this.create(`${id}/switch`)();
  }

  public getDefaultWorkspace() {
    return this.getWorkspaces().pipe(
      map((ws) => ws?.[0]),
      catchError((err) => {
        if (err.status === 400) return of(null);
        return throwError(err);
      })
    );
  }

  public createWorkspace(body: Partial<Workspace>): Observable<Workspace> {
    const oldValues = this._workspaces$?.value ?? [];
    return this.create('')({ status: 'active', ...body }).pipe(
      tap((ws) => {
        this._workspaces$.next([...oldValues, ws]);
      })
    );
  }

  public updateWorkspace(id: string, body: object): Observable<Workspace> {
    const oldValues = this._workspaces$?.value ?? [];
    return this.update()(id, body).pipe(
      tap((ws) => {
        oldValues.forEach((val, key) => {
          if (val._id === ws._id) {
            oldValues[key] = ws;
          }
        });
        this._workspaces$.next([...oldValues]);
        this._currentWorkspace$.next(ws);
      })
    );
  }

  public deleteWorkspace(id: string): Observable<Workspace> {
    const oldValues = this._workspaces$?.value ?? [];
    return this.update()(id, { status: 'archived' }).pipe(
      tap((ws) => {
        oldValues.forEach((val, key) => {
          if (val._id === ws._id) {
            oldValues.splice(key, 1);
          }
        });
        this._workspaces$.next([...oldValues]);
        this._currentWorkspace$.next(ws);
      })
    );
  }

  public buildWorkspaceBody(name: string, thumbnail: FileData): Partial<Workspace> {
    const workspace: Partial<Workspace> = {
      name,
      status: 'active'
    };
    if (thumbnail) {
      workspace.meta = {
        thumbnail: {
          id: thumbnail.id
        }
      };
    }
    return workspace;
  }

  private buildUserIdsParam(userData) {
    return userData.reduce(
      (p, usr, i) => ({
        ...p,
        [`ref[$in][${i}]`]: usr._id
      }),
      {}
    );
  }

  private listUserListMedia(userIds, user) {
    this.mediaService
      .listMedia({
        type: 'profile',
        status: 'active',
        ...userIds
      })
      .pipe(pluck('data'))
      .subscribe((media) => {
        media.forEach((med) => {
          const key = user.data.findIndex((el) => el._id === med.ref);
          user.data[key].avatar = med._id;
        });
      });
    return user;
  }
}
